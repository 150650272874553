// The module cache
var __pngack_module_cache__ = {};

// The require function
function __pngack_require__(moduleId) {
	// Check if module is in cache
	var cachedModule = __pngack_module_cache__[moduleId];
	if (cachedModule !== undefined) {
		return cachedModule.exports;
	}
	// Create a new module (and put it into the cache)
	var module = __pngack_module_cache__[moduleId] = {
		// no module.id needed
		// no module.loaded needed
		exports: {}
	};

	// Execute the module function
	__pngack_modules__[moduleId](module, module.exports, __pngack_require__);

	// Return the exports of the module
	return module.exports;
}

